.rawsonFooterA {
    background: rgb(107,226,219);
    background: linear-gradient(114deg, rgba(107,226,219,1) 0%, rgba(9,133,125,1) 100%);
}
.rawsonFooter {
    background: rgb(107,226,219);
    background: linear-gradient(114deg, rgba(107,226,219,1) 0%, rgba(9,133,125,1) 100%);
    margin-top: 0rem;
}
.escudo{
    margin-right: 3rem;
    padding: 1rem;
}

.modernizacion{
    float: right;
}
.contacto{
    margin-top: 1rem;
    text-align-last: center ;
}
.text-Links{
    color: rgb(189, 225, 236) !important;
}
