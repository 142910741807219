.rawsonNavBAr {
  background: rgb(107,226,219) !important;
  background: linear-gradient(114deg, rgba(107,226,219,1) 0%, rgba(9,133,125,1) 100%) !important;
}
.rawsonLinks{
  margin-left: 3rem !important;
}
.linkNoneDec{
  color: black !important;
  text-decoration: none !important;
}

#opcionDropDown:active {
  background: rgb(9,133,125,1) !important;
}